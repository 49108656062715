<script>
import Fuse from 'fuse.js';
import { VAutocomplete, VRow, VCol, VRadio, VRadioGroup } from 'vuetify/lib';
import { mapActions, mapGetters } from 'vuex';

import debounceMixin from '@/mixins/debounceMixin';
import { QUERY_DESTINATIONS } from '@/store/modules/Destination/actions';
import { convertGoogleAddress } from '@/util';

export default VAutocomplete.extend({
  name: 'DestinationAutocomplete',
  mixins: [debounceMixin],
  inject: ['googleCore', 'googlePlaces'],
  data() {
    return {
      autocompleteService: {},
      internalLoading: false,
      placesService: {},
      radioInput: 'establishment',
    };
  },
  props: {
    appendIcon: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    debounceDelay: {
      type: Number,
      default: 300,
    },
    destinationFilters: {
      type: Object,
      default: () => ({}),
      require: false,
    },
    filter: {
      type: Function,
      default: function (item, queryText, itemText) {
        const fuseSearch = new Fuse([item], { keys: ['name'] });
        return !!fuseSearch.search(queryText).length;
      },
    },
    hideNoData: {
      type: Boolean,
      default: true,
    },
    includeDestinations: {
      type: Boolean,
      default: true,
    },
    includePlaces: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text',
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'id',
    },
    placeholder: {
      type: String,
      default: 'Begin typing to search for a destination...',
    },
    semesterId: {
      type: Number,
      default: -1,
    },
    radioLabel: {
      type: String,
      default: 'Search new destinations by:',
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('app', ['clientBounds']),
  },
  created() {
    this.initGooglePlaces();
    this.onInternalSearchChangedDebounced = this.debounce(this.handleSearch, this.debounceDelay);
  },
  mounted() {
    this.onInternalSearchChangedDebounced();
  },
  methods: {
    ...mapActions('destination', [QUERY_DESTINATIONS]),
    formatPredictions(predictions) {
      if (!predictions || !predictions.length) return [];

      return predictions.map((prediction) => {
        prediction.text = prediction.description;
        prediction.name = prediction.description;
        prediction.id = prediction.place_id;

        return prediction;
      });
    },
    handleDetailsResponse(resolve, reject, result, status) {
      if (status !== this.googlePlaces.PlacesServiceStatus.OK) return reject(status);

      return resolve(result);
    },
    async handleSearch(searchValue = '') {
      if (!searchValue && !this.lazyValue) {
        if (!this.cacheItems) this.cachedItems = [];
        return;
      }

      this.internalLoading = true;

      searchValue = searchValue ? searchValue.trim() : '';

      const items = [];
      let destinations = [];

      if (this.includeDestinations) {
        const options = {
          ...this.destinationFilters,
          addressPicker: 1,
          filterId: this.lazyValue,
          filterText: searchValue,
          limit: 10,
          semesterId: this.semesterId === -1 ? undefined : this.semesterId,
          showProspects: true,
        };

        destinations = await this[QUERY_DESTINATIONS](options);

        destinations.forEach((destination) => {
          if (!destination.address) return;

          const addressString = Object.entries(destination.address)
            .reduce((acc, [key, value]) => {
              if (!value) return acc;

              if (key === 'address' || key === 'address2' || key === 'city' || key === 'zip' || key === 'state') {
                acc.push(value);
              }

              return acc;
            }, [])
            .join(', ');

          destination.text = `${destination.name} ${addressString ? ` (${addressString})` : ''}`;
        });

        if (destinations.length > 0) items.push(...destinations);
      }

      const onlyThisDestinationFound =
        destinations.find((val) => val.id === this.lazyValue) && destinations.length === 1;

      const searchGooglePlaces =
        this.includePlaces && searchValue && (onlyThisDestinationFound || !destinations.length);

      if (searchGooglePlaces) {
        const results = await this.requestPredictions();
        const predictions = this.formatPredictions(results);

        items.push(...predictions);
      }

      this.cachedItems = items;
      this.setSelectedItems();
      this.updateMenuDimensions();
      this.internalLoading = false;
    },
    initGooglePlaces() {
      if (!this.includePlaces) return;

      const li = document.createElement('li');

      this.autocompleteService = new this.googlePlaces.AutocompleteService();
      this.placesService = new this.googlePlaces.PlacesService(li);
    },
    onInternalSearchChanged() {
      this.onInternalSearchChangedDebounced(this.internalSearch);
    },
    async requestPredictions(increaseBounds = 0) {
      let bounds = new this.googleCore.LatLngBounds(
        new this.googleCore.LatLng(this.clientBounds[1], this.clientBounds[0]),
        new this.googleCore.LatLng(this.clientBounds[3], this.clientBounds[2])
      );

      if (increaseBounds) {
        const bounding = bounds.toJSON();

        bounds.extend(new this.googleCore.LatLng(bounding.north + increaseBounds / 2, bounding.east + increaseBounds));
        bounds.extend(new this.googleCore.LatLng(bounding.south - increaseBounds / 2, bounding.west - increaseBounds));
      }

      const options = {
        componentRestrictions: { country: ['us', 'ca'] },
        input: this.internalSearch,
        locationBias: bounds,
        types: [this.radioInput],
      };

      let results = (await this.autocompleteService.getPlacePredictions(options)).predictions;

      if ((!results || results.length <= 2) && increaseBounds <= 60) {
        const nextResults = await this.requestPredictions(increaseBounds + 60);

        results = results ? results.concat(nextResults) : [...nextResults];
      }

      return results;
    },
    setDestinationToValue(value, preventChange = false) {
      if (this.valueComparator(value, this.internalValue)) return;

      this.internalValue = value;

      if (preventChange) return;
      this.$emit('change', value);
    },
    async setPlaceDetails(prediction) {
      const result = await new Promise((resolve, reject) => {
        this.placesService.getDetails({ placeId: prediction.place_id }, (result, status) =>
          this.handleDetailsResponse(resolve, reject, result, status)
        );
      });

      if (!result) return;

      const address = convertGoogleAddress(result);

      prediction.address = {
        ...(address ?? {}),
        name: prediction.name,
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };

      return prediction;
    },
    setValue(value) {
      if (!value) {
        if (!this.cacheItems) this.cachedItems = [];

        this.setDestinationToValue(null);
        this.$emit('destinationChanged', null);

        return;
      }

      if (!value.place_id) {
        this.setDestinationToValue(value.id);
        this.$emit('destinationChanged', value);

        return;
      }

      this.setPlaceDetails(value).then((result) => {
        if (!result) return;
        if (!result.address) result.address = {};

        this.setDestinationToValue(result.id, true);
        this.$emit('destinationChanged', result);
      });
    },
    isInternallyLoading(val) {
      this.$emit('internalLoading', val);
    },
    genRadios() {
      if (!this.includePlaces) return;

      return this.$createElement(
        VRadioGroup,
        {
          props: {
            column: false,
            dense: true,
            hideDetails: true,
            row: true,
            value: this.radioInput,
            readonly: this.readonly,
          },
          on: {
            change: (value) => {
              this.radioInput = value;
              this.handleSearch(this.internalSearch);
            },
          },
          data: VRadioGroup.options.data,
        },
        [
          this.$createElement('p', { class: 'mr-4 mb-0' }, this.radioLabel),
          this.$createElement(VRadio, {
            props: { label: 'Business', value: 'establishment', dense: true },
            data: VRadio.options.data,
          }),
          this.$createElement(VRadio, {
            props: { label: 'Address', value: 'address', dense: true },
            data: VRadio.options.data,
          }),
        ]
      );
    },
    genContent() {
      return [this.genPrependSlot(), this.genControl(), this.genAppendSlot()];
    },
  },
  watch: {
    internalLoading: 'isInternallyLoading',
    value(value) {
      if (!value) return;

      this.handleSearch();
    },
    searchInput(value) {
      if (value) return;

      this.lazyValue = null;
      this.lazySearchValue = null;
    },
  },
  render(createElement) {
    return createElement(VCol, { class: 'pt-2 pb-0' }, [
      this.$createElement(VRow, { class: 'mb-2', justify: 'center', align: 'center' }, [this.genRadios()]),
      this.$createElement(VRow, { class: 'mb-2', justify: 'center', align: 'center' }, [
        VAutocomplete.options.render.call(this, createElement),
      ]),
    ]);
  },
});
</script>

<style type="scss" scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
